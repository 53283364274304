@import '../../../../../../../Foundation/Styling/code/thule/global/media-queries.less';
@import '@{variablesLocation}';

.hellobar-component {
  min-height: 4.4rem;
  color: @Standard;
  @media (max-width: @screen-md-max) {
    height: 4.4rem;
  }

  a,
  a:hover,
  a:active,
  a:visited {
    text-decoration: underline;
  }
  .splide__list {
    align-items: center;
  }

  @media (min-width: @screen-lg) {
    .hello-bar-item {
      padding-left: 15rem;
      padding-right: 15rem;
    }
  }
}
